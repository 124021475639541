@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-BlackItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-BlackItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-BlackItalic.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Black.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Black.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-BoldItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-BoldItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-BoldItalic.ttf')
      format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Bold.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Bold.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-HeavyItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-HeavyItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-HeavyItalic.ttf')
      format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Heavy.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Heavy.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Heavy.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-LightItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-LightItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-LightItalic.ttf')
      format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Light.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Light.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFPro-Regular.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFPro-Regular.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFPro-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-MediumItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-MediumItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-MediumItalic.ttf')
      format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Medium.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Medium.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Regular.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Regular.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-RegularItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-RegularItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-RegularItalic.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Semibold.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Semibold.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Semibold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-SemiboldItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-SemiboldItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-SemiboldItalic.ttf')
      format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Thin.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Thin.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Thin.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-ThinItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-ThinItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-ThinItalic.ttf')
      format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Ultralight.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Ultralight.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-Ultralight.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-UltralightItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-UltralightItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProDisplay-UltralightItalic.ttf')
      format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Black.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Black.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Bold.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Bold.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Bold.ttf')
      format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFPro-RegularItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFPro-RegularItalic.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFPro-RegularItalic.ttf')
      format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Heavy.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Heavy.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Heavy.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Light.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Light.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Light.ttf')
      format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Medium.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Medium.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Medium.ttf')
      format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Regular.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Regular.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Regular.ttf')
      format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Semibold.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Semibold.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Semibold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Thin.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Thin.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Thin.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SFRounded';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Ultralight.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Ultralight.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFRounded-Ultralight.ttf')
      format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Black.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Black.woff')
      format('woff'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Black.ttf')
      format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-BlackItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-BlackItalic.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Bold.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Bold.woff')
      format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-BoldItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-BoldItalic.woff')
      format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Heavy.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Heavy.woff')
      format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-HeavyItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-HeavyItalic.woff')
      format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Light.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Light.woff')
      format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-LightItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-LightItalic.woff')
      format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Medium.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-MediumItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-MediumItalic.woff')
      format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Regular.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Regular.woff')
      format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-RegularItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-RegularItalic.woff')
      format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Semibold.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Semibold.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-SemiboldItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-SemiboldItalic.woff')
      format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Thin.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Thin.woff')
      format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-ThinItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-ThinItalic.woff')
      format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Ultralight.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-Ultralight.woff')
      format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SFProText';
  src: url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-UltralightItalic.woff2')
      format('woff2'),
    url('https://d280crdu6dot2n.cloudfront.net/fonts/SFProText-UltralightItalic.woff')
      format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

.PullRefreshCss {
  padding-bottom: 100px !important;
}
.lds-ellipsis div {
  background-color: #ff9331 !important;
}
.root_app {
  padding-top: env(safe-area-inset-top);
}

.faviconMenuA {
  font-size: 30px !important;
  max-height: 30px !important;
  max-width: 30px !important;
}

.MenuAShoppingList {
  position: 'relative';
}
.root_app {
  padding-top: env(safe-area-inset-top);
}

.faviconMenuA {
  font-size: 30px !important;
  max-height: 30px !important;
  max-width: 30px !important;
}

.MenuAShoppingList {
  position: 'relative';
}

.SearchInputIcon {
  color: '#3C3C4399' !important;
}


