.ResizePanel-module_ResizeBarVertical__2LUZV {
  cursor: pointer;
  width: 71px;
  height: 10px;
  border-radius: 8px;
  background: transparent !important;
  /* border: 2px solid lightgray; */
  z-index: 10;
  overflow: hidden;
  /* display: -webkit-box; */
  /* display: flex !important; */
  justify-content: center;
  background: #dadbdf 0% 0% no-repeat padding-box;
  margin: auto;
  align-items: center;
  flex-direction: column;
  padding: 12px;
  margin-top: 22px;
  margin-bottom: -13px;
}
.ResizePanel-module_ResizeHandleVertical__I0jId {
  cursor: pointer !important;
  width: 71px;
  background-color: transparent !important;
  height: 5px;
}
