.header {
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 48rem;
  margin: 0 auto;
  z-index: 20;
  background-color: white;
}

.btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  overflow: hidden;
  background-color: white;
}

.menu-line {
  height: 3px;
  background-color: #f68422;
  border-radius: 2px;
  width: 28px;
}

.menu-line:nth-of-type(2) {
  width: 28px;
}

.mobile-nav {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: saturate(180%) blur(20px);
  position: fixed;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 2rem 2rem 6rem 2rem;
  z-index: 10;
}

.content-wrapper {
  margin-top: 3rem;
}

.list {
  list-style: none;
  padding: 0;
  margin-top: 60px;
}

.list-item {
  font-size: 2rem;
  font-weight: bold;
  margin: 1rem 0;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px #000000;
}

.icon-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
}

.icon:nth-of-type(1) {
  margin-right: 1.5rem;
}
