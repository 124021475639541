.image-container {
  background-color: white;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}
.image {
  height: 100%;
  opacity: 0;
  object-position: 0 0;
  background-color: ;
}

.ImagCover {
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
}
.imageContain {
  object-fit: contain;
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
}
.isLoaded {
  transition: opacity 1s ease-out;
  opacity: 1;
}
